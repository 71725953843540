export const toolTipConstant = {
    visibility: 'TOOLTIP.VISIBILITY',
    formImported: 'TOOLTIP.FORM_IMPORTED',
    outOfLimited: 'TOOLTIP.OUT_OF_LIMITED_FORM',
    deletionRequest: 'TOOLTIP.DELETION_REQUEST',
    responseDeletionOutOf: 'TOOLTIP.RESPONSES_DELETE_OUT_OF',
    formResponses: 'TOOLTIP.FORM_RESPONSES',
    previewWorkspace: 'TOOLTIP.PREVIEW_WORKSPACE',
    requestForDeletion: 'TOOLTIP.REQUEST_FOR_DELETION',
    alreadyRequestedForDeletion: 'TOOLTIP.ALREADY_REQUESTED_FOR_DELETION',
    editWorkspace: 'TOOLTIP.EDIT_WORKSPACE',
    shareWorkspace: 'TOOLTIP.SHARE_WORKSPACE',
    formProviderHidden: 'TOOLTIP.FORM_PROVIDER_HIDDEN',
    copyLink: 'TOOLTIP.COPY_LINK',
    hideForm: 'TOOLTIP.HIDE_FORM',
    pinned: 'TOOLTIP.PINNED',
    Options: 'TOOLTIP.OPTIONS',
    formOptions: 'TOOLTIP.FORM_OPTIONS',
    shareForm: 'TOOLTIP.SHARE_FORM',
    formIsAlreadyOnGroup: 'TOOLTIP.FORM_IS_ALREADY_ON_GROUP',
    noAccessToGroup: 'TOOLTIP.NO_ACCESS_TO_GROUP',
    emptyFormOnWorkspace: 'TOOLTIP.EMPTY_FORM_ON_WORKSPACE'
};
