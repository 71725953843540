import React from 'react';

export function GoogleFormIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48" {...props}>
            <path fill="#673ab7" d="M12,44c-1.657,0-3-1.343-3-3V7c0-1.657,1.343-3,3-3h16l11,11v26c0,1.657-1.343,3-3,3H12z"></path>
            <path fill="#5e35b1" d="M39 15L28.883 14.125 39 24.124z"></path>
            <path fill="#b39ddb" d="M39,15h-8c-1.657,0-3-1.343-3-3V4L39,15z"></path>
            <path
                fill="#f5f5f5"
                d="M22 23H32V25H22zM22 28H32V30H22zM22 33H32V35H22zM17.5 22.5A1.5 1.5 0 1 0 17.5 25.5 1.5 1.5 0 1 0 17.5 22.5zM17.5 27.5A1.5 1.5 0 1 0 17.5 30.5 1.5 1.5 0 1 0 17.5 27.5zM17.5 32.5A1.5 1.5 0 1 0 17.5 35.5 1.5 1.5 0 1 0 17.5 32.5z"
            ></path>
        </svg>
    );
}