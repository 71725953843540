import React from 'react';

export function HistoryIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.3846 6.28504C10.3846 5.90269 10.0747 5.59273 9.69231 5.59273H4.15385C3.7715 5.59273 3.46154 5.90269 3.46154 6.28504C3.46154 6.66739 3.7715 6.97735 4.15385 6.97735H9.69231C10.0747 6.97735 10.3846 6.66739 10.3846 6.28504Z"
                fill="currentColor"
            />
            <path
                d="M9.46154 9.05427C9.46154 8.67192 9.15158 8.36196 8.76923 8.36196H4.15385C3.7715 8.36196 3.46154 8.67192 3.46154 9.05427C3.46154 9.43662 3.7715 9.74658 4.15385 9.74658H8.76923C9.15158 9.74658 9.46154 9.43662 9.46154 9.05427Z"
                fill="currentColor"
            />
            <path
                d="M9.69231 11.1312C10.0747 11.1312 10.3846 11.4411 10.3846 11.8235C10.3846 12.2059 10.0747 12.5158 9.69231 12.5158H4.15385C3.7715 12.5158 3.46154 12.2059 3.46154 11.8235C3.46154 11.4411 3.7715 11.1312 4.15385 11.1312H9.69231Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.46154 17.362H15.4615C16.8635 17.362 18 16.2255 18 14.8235V9.74658C18 9.36423 17.69 9.05428 17.3077 9.05428H14.3077V1.84799C14.3077 0.53408 12.8226 -0.230197 11.7534 0.533497L11.5918 0.648913C10.8714 1.16352 9.89546 1.16179 9.17163 0.644769C7.96806 -0.214923 6.33963 -0.214923 5.13607 0.644769C4.41223 1.16179 3.43634 1.16352 2.71589 0.648913L2.55431 0.533497C1.48514 -0.230197 0 0.534081 0 1.84799V13.9004C0 15.8122 1.54978 17.362 3.46154 17.362ZM5.94086 1.77148C6.663 1.25566 7.64469 1.25566 8.36684 1.77148C9.56871 2.62996 11.1913 2.63652 12.3966 1.77562L12.5582 1.66021C12.7109 1.55111 12.9231 1.66029 12.9231 1.84799V14.8235C12.9231 15.239 13.0229 15.6312 13.1999 15.9774H3.46154C2.31449 15.9774 1.38462 15.0475 1.38462 13.9004V1.84799C1.38462 1.66029 1.59678 1.55111 1.74952 1.66021L1.9111 1.77562C3.11636 2.63652 4.73898 2.62996 5.94086 1.77148ZM14.3077 14.8235V10.4389H16.6154V14.8235C16.6154 15.4608 16.0988 15.9774 15.4615 15.9774C14.8243 15.9774 14.3077 15.4608 14.3077 14.8235Z"
                fill="currentColor"
            />
        </svg>
    );
}